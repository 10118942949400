import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import Bridge from './Bridge'
import { color } from '@mui/system'

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#000000',
        },
    },
});

function Copyright(props) {
    return (
        <Typography variant="body1" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.cheekyunts.com">
                Cheeky Unts
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function App() {
    return (

        <Container component="main" maxWidth="lg" sx={{ backgroundColor: 'black' }}>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0',
                    padding: '0',
                }}
            >
                {/* <Typography component="h1" variant="h5">
                            Skate Unt - Almost Alpha Demo
                        </Typography> */}
                <Box sx={{ mt: 8 }}>
                    <Bridge />
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4, color: 'white' }} />
        </Container>

    )

}
